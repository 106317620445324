<template>
  <div>
    <!-- 介绍 -->
    <div class="introduce">
      <div class="introduce_left">
        <div class="introduce_left_title">{{ $t("introduceTitle") }}</div>
        <div class="introduce_left_content">{{ $t("introduceContent") }}</div>
      </div>
      <img
        class="introduce_right"
        width="480px"
        src="../../assets/ai.png"
        alt="AI.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style scoped>
/* 介绍 */
.introduce {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  box-sizing: border-box;
}

.introduce_left {
  flex: 0 0 36%;
  text-align: left;
}

.introduce_left_title {
  font-size: 2rem;
  font-weight: 800;
  font-family: "YouSheBiaoTiHei";
  font-style: normal;
  background: linear-gradient(180deg, #4f80ff 0%, #896bff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.introduce_left_content {
  margin-top: 1.375rem;
  color: #999999;
  line-height: 1.375rem;
  font-size: 0.8125rem;
}
</style>
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "indexContent",
  data() {
    return {};
  },
  mounted() {
    document.title = this.$t("title");
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f2f5fe;
}

.index {
  margin: 0 auto;
  width: 1140px;
}
</style>

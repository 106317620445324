<template>
  <div class="index">
    <Header></Header>
    <introduce></introduce>
    <indexContent></indexContent>
    <copyRight></copyRight>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/index/Header.vue";
import introduce from "@/components/index/introduce.vue";
import indexContent from "@/components/index/indexContent.vue";
import copyRight from "@/components/copyRight.vue";
export default {
  name: "index",
  components: {
    Header,
    introduce,
    indexContent,
    copyRight,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
